<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Buddies" subtitle="Keep in touch with your friends and see what they're playing." :tabs="tabs" >

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="No buddies found." :is-grid="true">

		<app-buddy v-for="item in collection" :key="item.key" :buddy="item" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			pagination: false,
			endpoint: 'convention/buddies'
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Buddies', text: 'Buddies' },
				{ name: 'Convention.Buddies.Suggested', text: 'Suggested' },
				{ name: 'Convention.Buddies.Directory', text: 'Directory' }
			]

		}

	}

}

</script>

<style scoped>

</style>